import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

function SwapRight({
  devTotal,
  liqTotal,
  investTotal,
  rewardTotal,
  costTotal,
  totalValue,
}) {
  return (
    <div className="swapright">
      <div className="header">Running total</div>
      <div className="swaptax">
        <div className="taxname">Developer</div>
        <div className="taxamount">{devTotal} $</div>
      </div>
      <div className="swaptax">
        <div className="taxname">Liquidity</div>
        <div className="taxamount">{liqTotal} $</div>
      </div>
      <div className="swaptax">
        <div className="taxname">Cost</div>
        <div className="taxamount">{costTotal} $</div>
      </div>
      <div className="swaptax">
        <div className="taxname">Investment</div>
        <div className="taxamount">{investTotal} $</div>
      </div>
      <div className="swaptax">
        <div className="taxname">Reward</div>
        <div className="taxamount">{rewardTotal} $</div>
      </div>

      <div className="swaptax">
        <div className="taxname">UserWallet</div>
        <div className="taxamount">
          {parseFloat(devTotal) +
            parseFloat(rewardTotal) +
            parseFloat(liqTotal) +
            parseFloat(costTotal) +
            parseFloat(investTotal)}
          $
        </div>
      </div>
      <div className="swaptax">
        <div className="taxname">Total user wallets</div>
        <div className="taxamount">{totalValue} $</div>
      </div>
      {/* <div className="swaptax">
        <div className="taxname">Reward tax</div>
        <div className="taxamount">500</div>
      </div>
      <div className="swaptax">
        <div className="taxname">Reward tax</div>
        <div className="taxamount">500</div>
      </div> */}
    </div>
  );
}

export default SwapRight;
