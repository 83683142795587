import React, { useEffect, useState } from "react";
import "./swap.css";
import { CRYPTOCURRENCY } from "../../assets/images/crypto";
import { MdLocalGasStation, MdTurnedIn } from "react-icons/md";
import { AiOutlineArrowUp, AiOutlineSetting } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import WalletModal from "../../components/modals/walletsModal/WalletModal";
import SwapInput from "../../components/inputs/SwapInput";
import SwapRight from "./SwapRight";
import { ethers } from "ethers";
import swapABI from "./abi.json";
import usdcABI from "./usdcAbi.json";
import tadABI from "./tadAbi.json";
import BigNumber from "bignumber.js";
import { Icon } from "@iconify/react";
import axios from "axios";
import SwapRight2 from "./SwapRight2";

function Swap() {
  const [open, setopen] = useState(false);
  const [close, setclose] = useState(false);
  const [temp, settemp] = useState("");
  const [val, setval] = useState(true);
  const [amountIn, setAmountIn] = useState(0);
  const [amountOut, setAmountOut] = useState(0);

  const [cost, setCost] = useState(0);
  const [invest, setInvest] = useState(0);
  const [reward, setReward] = useState(0);
  const [dev, setDev] = useState(0);
  const [liq, setLiq] = useState(0);

  const [costTotal, setCostTotal] = useState(0);
  const [investTotal, setInvestTotal] = useState(0);
  const [rewardTotal, setRewardTotal] = useState(0);
  const [devTotal, setDevTotal] = useState(0);
  const [liqTotal, setLiqTotal] = useState(0);
  const [totalValue, setTotalValue] = useState(0);

  const swapAddress = "0xDC4bAB6D2C4B0e32d4357559aCDF53f13Bb6B99b";
  const usdcAddress = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
  const tadAddress = "0x965a0ed8b237c72969c310b0b098f064ec59f55e";

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_AXIOS_URL}/api/v1/tad`).then((res) => {
      if (res.data.data == null) {
        setCostTotal(0);
        setInvestTotal(0);
        setRewardTotal(0);
        setDevTotal(0);
        setLiqTotal(0);
      } else {
        setCostTotal(res.data.data.costTaxTotal.toFixed(3));
        setInvestTotal(res.data.data.investmentTaxTotal.toFixed(3));
        setRewardTotal(res.data.data.rewardTaxTotal.toFixed(3));
        setDevTotal(res.data.data.devTaxTotal.toFixed(3));
        setLiqTotal(res.data.data.liqTaxTotal.toFixed(3));
        setTotalValue(res.data.data.totalValue.toFixed(3));
      }
      console.log(res.data);
    });
  }, []);

  const setValues = (value) => {
    setAmountIn(value);
    if (val) {
      let actVal = value - (value * 8) / 100;
      let taxVal = (value * 8) / 100;

      let decLiqTax = taxVal / 4;
      let devTax = decLiqTax / 4;
      let liqTax = (decLiqTax * 3) / 4;
      let costTax = taxVal / 4;
      let investTax = taxVal / 4;
      let rewardTax = taxVal / 4;

      setTimeout(() => {
        setAmountOut(actVal);
        setDev(devTax.toFixed(3));
        setLiq(liqTax.toFixed(3));
        setCost(costTax.toFixed(3));
        setInvest(investTax.toFixed(3));
        setReward(rewardTax.toFixed(3));
      }, 1000);
    } else {
      setTimeout(() => {
        setAmountOut(value);
      }, 1000);
    }
  };

  const swap = () => {
    // settemp(first)
    setval(!val);

    // [first, secount] = [secount, first];
    var obj1 = document.getElementById("up").value;
    var obj2 = document.getElementById("down").value;
    var temp = obj1;
    obj1 = obj2;
    obj2 = temp;

    // Save the swapped values to the input element.
    document.getElementById("up").value = obj1;
    document.getElementById("down").value = obj2;
    setAmountIn(0);
    setAmountOut(0);
  };

  const swapAmounts = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    console.log(signer);

    const swapContract = connect(swapAddress, swapABI, signer);
    console.log(swapContract);
    const usdcContract = connect(usdcAddress, usdcABI, signer);
    console.log(usdcContract);
    const tadContract = connect(tadAddress, tadABI, signer);
    console.log(tadContract);
    console.log(amountIn);
    let weiValue = new BigNumber(amountIn * 10 ** 18);

    if (val) {
      const tx = await usdcContract.approve(swapAddress, weiValue.toString());
      await tx.wait();
      const res_tx = await swapContract.swap(usdcAddress, amountIn, {
        value: "10000000000000000",
        gasLimit: 300000,
      });
      await res_tx.wait();
      const save_data = {
        devTaxTotal: dev,
        liqTaxTotal: liq,
        investmentTaxTotal: invest,
        costTaxTotal: cost,
        rewardTaxTotal: reward,
        totalValue: amountOut,
      };

      await axios
        .post(`${process.env.REACT_APP_AXIOS_URL}/api/v1/tad`, save_data)
        .then((res) => {
          console.log(res.data);
          setCostTotal(parseFloat(res.data.data.costTaxTotal).toFixed(3));
          setInvestTotal(
            parseFloat(res.data.data.investmentTaxTotal).toFixed(3)
          );
          setRewardTotal(parseFloat(res.data.data.rewardTaxTotal).toFixed(3));
          setDevTotal(parseFloat(res.data.data.devTaxTotal).toFixed(3));
          setLiqTotal(parseFloat(res.data.data.liqTaxTotal).toFixed(3));
          setTotalValue(parseFloat(res.data.data.totalValue).toFixed(3));
        });
    } else {
      const tx = await tadContract.approve(swapAddress, weiValue.toString());
      await tx.wait();
      const res_tx = await swapContract.swap(tadAddress, amountIn, {
        value: "10000000000000000",
        gasLimit: 260000,
      });
      await res_tx.wait();
      console.log(res_tx);
    }
  };

  const connect = (address, abi, signerOrProvider) => {
    return new ethers.Contract(address, abi, signerOrProvider);
  };

  // function toWei(number) {
  //   return number * Math.pow(10, 18);
  // }

  return (
    <>
      <div className="swap">
        <img src={CRYPTOCURRENCY.ARTBOARD2} alt="imp" className="artboardimg" />

        <div className="swapbox">
          <header>
            Swap <AiOutlineSetting />
          </header>
          <div className="form-control-swap">
            {/* <SwapInput
              logo={val ? CRYPTOCURRENCY.POLYGON : CRYPTOCURRENCY.ETH}
              id={"up"}
              name={val ? "POL" : "TAD"}
            /> */}
            <div className="form-control-swap-input">
              <input
                type="number"
                placeholder="0"
                id={"up"}
                value={amountIn}
                onChange={(e) => setValues(e.target.value)}
              />
              <button>
                {val ? (
                  <Icon icon="cryptocurrency-color:usdc" />
                ) : (
                  <img src={CRYPTOCURRENCY.ETH} alt="" />
                )}
                {val ? "USDC" : "TAD"}
              </button>
            </div>
            <button className="arrowbtn" onClick={() => swap()}>
              <AiOutlineArrowUp />
            </button>
            {/* <label>$1000</label> */}
          </div>

          <div className="form-control-swap">
            {/* <SwapInput
              logo={!val ? CRYPTOCURRENCY.POLYGON : CRYPTOCURRENCY.ETH}
              id={"down"}
              name={!val ? "POL" : "TAD"}
            /> */}
            <div className="form-control-swap-input">
              <input
                type="number"
                placeholder="0"
                id={"down"}
                value={amountOut}
                readOnly
              />
              <button>
                {!val ? (
                  <Icon icon="cryptocurrency-color:usdc" />
                ) : (
                  <img src={CRYPTOCURRENCY.ETH} alt="" />
                )}

                {!val ? "USDC" : "TAD"}
              </button>
            </div>

            {/* <label>$1000</label> */}
            <div className="drop-container">
              <header>
                {val ? (
                  <div>
                    {amountIn} USDC = {amountOut} TAD
                    {/* <span>($1,587.72)</span> */}
                  </div>
                ) : (
                  <div>
                    {amountIn} TAD = {amountOut} USDC
                  </div>
                )}

                <div>
                  <MdLocalGasStation />
                  {/* <span>@3.34</span> */}
                  {open ? (
                    <IoIosArrowDown onClick={() => setopen(!open)} />
                  ) : (
                    <IoIosArrowUp onClick={() => setopen(!open)} />
                  )}
                </div>
              </header>
              {open ? (
                <table>
                  {val ? (
                    <tbody>
                      <tr>
                        <td>Expected Output</td>
                        <td className="tablefirst">{amountOut} TAD</td>
                      </tr>
                      <tr>
                        <td>Tax</td>
                        <td>8 %</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>Expected Output</td>
                        <td className="tablefirst">{amountOut} USDC</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              ) : null}
            </div>
          </div>
          <button className="connect-wallet" onClick={() => swapAmounts()}>
            Swap
          </button>
        </div>
        {val ? (
          <div className="swap-right">
            <SwapRight
              costTotal={costTotal}
              investTotal={investTotal}
              rewardTotal={rewardTotal}
              liqTotal={liqTotal}
              devTotal={devTotal}
              totalValue={totalValue}
            />
            <SwapRight2
              cost={cost}
              invest={invest}
              reward={reward}
              liq={liq}
              dev={dev}
              userWal={amountOut}
            />
          </div>
        ) : <div className="swap-right3"></div>
        }
      </div>
      <WalletModal open={close} setopen={setclose} />
    </>
  );
}

export default Swap;
