import "./App.css";
import Home from "./Home";
import { createClient, WagmiConfig } from "wagmi";
import { getDefaultProvider } from "ethers";
function App() {
  const client = createClient({
    autoConnect: true,
    
    provider: getDefaultProvider()
  });
  return (
    <WagmiConfig client={client}>
      <Home />
    </WagmiConfig>
  );
}

export default App;
