import React from "react";
import NavBar from "./components/navbar/NavBar";
import Swap from "./views/swap/Swap";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function Home() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Swap />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Home;
