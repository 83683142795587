import React, { useState } from "react";
import { CRYPTOCURRENCY } from "../../assets/images/crypto";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineCheck } from "react-icons/ai";
const cripto = [];
function NavCoins() {
  const [open, setopen] = useState(false);
  return (
    <div className="cryptoDrop">
      <img src={CRYPTOCURRENCY.POLYGON} alt="" />
      <span className="chain">Polygon</span>
      {/* {open ? (
        <IoIosArrowDown onClick={() => setopen(!open)} />
      ) : (
        <IoIosArrowUp onClick={() => setopen(!open)} />
      )} */}
      <ul style={{ display: !open ? "none" : "block" }}>
        <li>
          <div>
            <img src={CRYPTOCURRENCY.ETH} alt="" />
            Etherium
          </div>
          <AiOutlineCheck />
        </li>
        <li>
          <div>
            <img src={CRYPTOCURRENCY.ETH} alt="" />
            Etherium
          </div>
          {/* <AiOutlineCheck /> */}
        </li>
        <li>
          <div>
            <img src={CRYPTOCURRENCY.ETH} alt="" />
            Etherium
          </div>
          <AiOutlineCheck />
        </li>
      </ul>
    </div>
  );
}

export default NavCoins;
