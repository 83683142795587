import React from "react";
import {  BiSearchAlt2 } from "react-icons/bi";

function SearchBar() {
  return (
    <div className="search-bar">
      <BiSearchAlt2 />
      <input type="search" placeholder="Search Tokens and Nft Collections" />
    </div>
  );
}

export default SearchBar;
