import React, { useState } from "react";
import "./walletModal.css";
import { IoClose } from "react-icons/io5";
import { CRYPTOCURRENCY } from "../../../assets/images/crypto";
import { Link } from "react-router-dom";
import { useAccount, useConnect, useSignMessage, useDisconnect } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

// {open,setopen}
function WalletModal({ open, setopen, setWalletAddress }) {
  // const [open, setopen] = useState(falssetWalletAddresse);
  const { connectAsync } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { isConnected } = useAccount();
  const handleAuth = async (wal) => {
    if (isConnected) {
      await disconnectAsync();
    }

    console.log("Connect To Site Via Wallet");

    const userData = {};

    if (wal === "meta") {
      try {
        const { account, chain, error } = await connectAsync({
          connector: new MetaMaskConnector({}),
        });

        userData.wallet_address = account;
        userData.chain = chain.id;
        userData.network = "metamask";
      } catch (error) {
        console.log("install metamask");
      }
    }
    if (wal === "walletconnect") {
      try {
        const { account, chain } = await connectAsync({
          connector: new WalletConnectConnector({
            options: {
              qrcode: true,
              rpc: {
                1: "https://eth-mainnet.alchemyapi.io/v2/yourAlchemyId",
              },
            },
          }),
        });

        userData.wallet_address = account;
        userData.chain = chain.id;
        userData.network = "walletconnect";
      } catch (error) {
        console.log("install wallet connect");
      }
    }

    if (wal === "coin") {
      try {
        const { account, chain } = await connectAsync({
          connector: new CoinbaseWalletConnector({
            options: {
              appName: "wagmi.sh",
              jsonRpcUrl: "https://eth-mainnet.alchemyapi.io/v2/yourAlchemyId",
            },
          }),
        });
        userData.wallet_address = account;
        userData.chain = chain.id;
        userData.network = "coinbase";
      } catch (error) {
        console.log("install coinbase");
      }
    }

    console.log(userData);

    setWalletAddress(userData.wallet_address);
  };
  async function connectAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log(accounts[0]);
    }
  }
  return (
    <div className="walletmodals" style={!open ? { display: "none" } : null}>
      <div className="walletModal">
        <header>
          Connect a Wallet
          <IoClose onClick={() => setopen(!open)} />
        </header>

        <button onClick={() => handleAuth("meta")}>
          <img src={CRYPTOCURRENCY.METAMASK} alt="" />
          Metamask
        </button>
        <button onClick={() => handleAuth("coin")}>
          <img src={CRYPTOCURRENCY.COINBASE} alt="" />
          CoinBase
        </button>
        <button onClick={() => handleAuth("walletconnect")}>
          <img src={CRYPTOCURRENCY.WALLETCONNECT} alt="" />
          WalletConnect
        </button>
        <p>
          By connecting a wallet, you agree to Uniswap Labs’{" "}
          <Link to="/">Terms of Service</Link> and consent to its{" "}
          <Link to="/">Privacy Policy</Link>.
        </p>
      </div>
    </div>
  );
}

export default WalletModal;
