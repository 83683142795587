import React, { useState } from "react";
import "./navbar.css";
import { FaHorseHead } from "react-icons/fa";
import SearchBar from "./SearchBar";
import NavWallets from "./NavWallets";
import NavCoins from "./NavCoins";
import NavVote from "./NavVote";
import { BiMenu } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
function NavBar() {
  const [pop, setpop] = useState(false);
  return (
    <nav>
      <div className="nav-link">
        <div className="logo">
          <FaHorseHead />
        </div>
        <BiMenu className="menuIcon" onClick={() => setpop(!pop)} />
        <ul className={ pop ? "" : "ullink" }>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "navlink-active" : undefined
              }
              to={"/"}
            >
              Swap
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="search">
        <SearchBar />
      </div>
      <div className="nav-right">
        <NavVote />
        <NavCoins />
        <NavWallets />
      </div>
    </nav>
  );
}

export default NavBar;
