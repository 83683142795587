import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

function SwapRight2({ cost, invest, liq, dev, reward, userWal }) {
  return (
    <div className="swapright2">
      <div className="header">Transaction Total</div>
      <div className="swaptax">
        <div className="taxname">
          Developer
          <AiOutlineInfoCircle />
        </div>
        <div className="taxamount">{dev} $</div>
      </div>
      <div className="swaptax">
        <div className="taxname">
          Liquidity
          <AiOutlineInfoCircle />
        </div>
        <div className="taxamount">{liq} $</div>
      </div>
      <div className="swaptax">
        <div className="taxname">
          Cost
          <AiOutlineInfoCircle />
        </div>
        <div className="taxamount">{cost} $</div>
      </div>
      <div className="swaptax">
        <div className="taxname">
          Investment
          <AiOutlineInfoCircle />
        </div>
        <div className="taxamount">{invest} $</div>
      </div>
      <div className="swaptax">
        <div className="taxname">
          Reward
          <AiOutlineInfoCircle />
        </div>
        <div className="taxamount">{reward} $</div>
      </div>
      <div className="swaptax">
        <div className="taxname">
          User Wallet
          <AiOutlineInfoCircle />
        </div>
        <div className="taxamount">{userWal} $</div>
      </div>
      {/* <div className="swaptax">
        <div className="taxname">
          Reward tax
          <AiOutlineInfoCircle />
        </div>
        <div className="taxamount">500</div>
      </div> */}
      {/* <div className="swaptax">
        <div className="taxname">
          Reward tax
          <AiOutlineInfoCircle />
        </div>
        <div className="taxamount">500</div>
      </div> */}
    </div>
  );
}

export default SwapRight2;
