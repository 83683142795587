import eth from "./ethimage.png";
import pol from "./polygon.jpg";
import arrowleft from "./arrowleft.png";
import metamask from "./MetaMask.png";
import coinbase from "./coinbase.png";
import walletconnect from "./WalletConnect.png";
import artboard2 from "./Artboard2.png";

export const CRYPTOCURRENCY = {
  ETH: eth,
  POLYGON: pol,
  COINBASE: coinbase,
  METAMASK: metamask,
  ARROWLEFT:arrowleft,
  WALLETCONNECT:walletconnect,
  ARTBOARD2:artboard2
};
