import React, { useState } from "react";
import { RxDividerVertical } from "react-icons/rx";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import WalletModal from "../modals/walletsModal/WalletModal";

function NavWallets() {
  const [open, setopen] = useState(false);
  const [close, setclose] = useState(false);

  const [walletAddress, setWalletAddress] = useState("");

  return (
    <>
      {walletAddress == "" ? (
        <>
          <div className="connectwallet">
            <button className="connectBtn" onClick={() => setclose(!close)}>
              Connect
            </button>
            <RxDividerVertical />
            {open ? (
              <IoIosArrowDown onClick={() => setopen(!open)} />
            ) : (
              <IoIosArrowUp onClick={() => setopen(!open)} />
            )}
          </div>
          <WalletModal
            open={close}
            setopen={setclose}
            setWalletAddress={setWalletAddress}
          />
        </>
      ) : (
        <>
          <div className="connectwallet">
            <div className="connectBtn">
              {walletAddress === null
                ? "-"
                : walletAddress
                ? `${walletAddress.substring(0, 6)}...${walletAddress.substring(
                    walletAddress.length - 6
                  )}`
                : ""}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NavWallets;
